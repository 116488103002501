<template>
    <v-container fluid tag="section">
        <v-card class="px-5 py-3">
            <v-tabs class="mb-3">
                <v-tab @click="selectChannel(channel.id)" v-for="channel in channelList" :key="channel.id">
                    <div v-if="channel.tag.length">{{channel.tag }}</div><div v-else>Без названия</div>
                </v-tab>
            </v-tabs>
            <v-row class="py-3 px-5" v-for="item, idx in mappedRooms" :key="idx">
                <v-col cols="6" sm="3">
                    <v-select v-model="item.bcom" label="Номер в booking.com" :items="bcomRooms" item-text="name"
                        item-value="id" />
                </v-col>
                <v-col cols="6" sm="3">
                    <v-select v-model="item.wbroom" label="Категория" :items="categoryList" item-text="name"
                        item-value="external_category_id" />
                </v-col>
                <v-col cols="6" sm="3">
                    <v-icon class="px-1" color="red" v-if="item.isCustom" small @click="remove(idx)">
                        mdi-delete
                    </v-icon>
                </v-col>
            </v-row>
            <v-row>

                <v-col>
                    <v-btn text color="primary" @click="add">
                        Добавить
                    </v-btn>
                </v-col>
                <v-col>
                    <v-btn text color="primary" @click="save">
                        Применить
                    </v-btn>
                </v-col>
            </v-row>
        </v-card>
    </v-container>
</template>
<script>
import { SalesApiUrls } from "@/services/accommodationRequests/sales.api.js";
import { CategoriesApiUrls } from "@/services/accommodationRequests/categories.api.js";

export default {
    data: () => ({
        channelList: [],
        bcomRooms: [],
        categoryList: [],
        mappedRooms: [],
        selectedChannelID: null,
    }),
    async created() {
        await this.getChannels();
        await this.getCategories()
    },
    methods: {
        async remove(idx) {
            this.mappedRooms.splice(idx, 1);
        },
        async add() {
            this.mappedRooms.push({
                bcom: "",
                wbroom: "",
                isCustom: true
            })
        },
        async save() {
            let postData = {};
            let alreadyAdded = [];
            let hasError = false;
            this.mappedRooms.forEach(element => {
                if (alreadyAdded.indexOf(element.bcom) == -1) {
                    alreadyAdded.push(element.bcom);
                } else {
                    hasError = true;

                }
                postData[element.bcom] = element.wbroom;
            })
            if (hasError) {
                alert("Already Exists");
                return;
            }
            let res = await CategoriesApiUrls.mapBcomRooms(this.selectedChannelID, postData);
        },
        async getChannels() {
            let res = await SalesApiUrls.getChannels();
            this.channelList = res;
            if (this.channelList.length > 0) {
                await this.selectChannel(this.channelList[0].id)
            }
        },
        async getBcomRooms(channel_id) {
            let res = await CategoriesApiUrls.getBcomRooms(channel_id);
            this.bcomRooms = res;
            this.bcomRooms.forEach(element => {
                this.mappedRooms.push(
                    {
                        bcom: element.id,
                        wbroom: element.wbroom.toString(),
                        isCustom: false
                    }
                )
            });
        },
        async getCategories() {
            let res = await CategoriesApiUrls.getCategoriesMapping();
            this.categoryList = res;
        },
        async clearData() {
            this.mappedRooms = [];
            this.bcomRooms = [];
            this.selectedChannelID = null;
        },
        async selectChannel(channel_id) {
            await this.clearData();

            await this.getBcomRooms(channel_id);
            this.selectedChannelID = channel_id;
        }
    }
}
</script>